import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { ROUTES } from "./utils/constants/variables/routes";
import Appointment from "./view/pages/Appointment";
import LastVisit from "./view/pages/LastVisit";
import LastVisitInfo from "./view/pages/LastVisitInfo";
import OnlineBooking from "./view/pages/OnlineBooking/bookAppointment";
import WaitingList from "./view/pages/OnlineBooking/waitingList";

const authRoutes = [
  {
    path: ROUTES.HOME,
    element: <Appointment />,
  },
];
const masterAdminRoutes = [
  {
    path: ROUTES.LAST_VISIT,
    element: <LastVisit />,
  },
  {
    path: ROUTES.LAST_VISIT_INFO,
    element: <LastVisitInfo />,
  },
  {
    path: ROUTES.BOOK_APPOINTMENT,
    element: <OnlineBooking />,
  },
  {
    path: ROUTES.WAITING_LIST,
    element: <WaitingList />,
  },
];

const routeConfig = [
  ...authRoutes,
  ...masterAdminRoutes,
  { path: "*", element: <Navigate to={ROUTES.HOME} replace /> },
];

const RouterWrapper = () => {
  /**
   *
   * @param {*} routes
   * @returns Routes & Nested Route Component recursively
   */
  function generateRoutes(routes) {
    return routes.map((route, index) => {
      const { path, element, children } = route;
      return (
        <Route key={index} path={path} element={element}>
          {children && children.length > 0 && generateRoutes(children)}
        </Route>
      );
    });
  }
  return (
    <>
      <BrowserRouter>
        <Routes>{generateRoutes(routeConfig)}</Routes>
      </BrowserRouter>
    </>
  );
};

export default RouterWrapper;
